<template>
  <v-container>
    <v-row class="row search spacer-md">
      <!-- Kontakt -->
      <v-col cols="12" sm="6" md="4" lg="2" v-if="!isFarmer">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <!-- Rechtliche Person -->
      <v-col cols="12" sm="6" md="4" lg="2" v-if="!isFarmer">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <!-- Betrieb -->
      <v-col cols="12" sm="6" md="4" lg="2" v-if="isFarmer">
        <search-farm-managed ref="search-farm-managed" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" v-else>
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <!-- Milchverwerter -->
      <v-col cols="12" sm="6" md="4" lg="2" v-if="isFarmer">
        <search-dairy-supplied ref="search-dairy-supplied" @search="search" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" v-else>
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
      <!-- Verkehrsmilch -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-production ref="search-production" @search="search" @clicked="onClickCheckboxSum" :isFarmer="isFarmer" />
      </v-col>
      <!-- Label -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        v-if="
          $privileges.has({
            path: '/productionFormRows/labelTypes',
            permission: 'search'
          }) ||
          $privileges.has({
            path: '/productionFormRows/labelTypes',
            permission: 'searchOrdered'
          })
        "
      >
        <search-production-label ref="search-production-label" @search="search" />
      </v-col>
    </v-row>

    <v-btn color="primary" @click="search" :loading="loading"><span v-html="$t('production_form_rows_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('production_form_rows_new_search')" /></v-btn>
    <span class="m-2">
      <v-menu class="m-2" offset-y>
        <template v-slot:activator="{ props }">
          <v-btn id="download_dropdown" color="secondary" v-bind="props" :loading="downloading">
            <span v-html="$t('production_form_rows_download')" />
            <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item id="production_form_rows_download" key="production_form_rows_download" @click="download">
            <v-list-item-title v-html="$t('production_form_rows_download')"></v-list-item-title>
          </v-list-item>
          <v-list-item
            id="production_form_rows_310_csv"
            key="production_form_rows_310_csv"
            v-if="
              $privileges.has({
                path: '/productionForms',
                permission: 'allDomains'
              })
            "
            @click="get310(-1, 'csv')"
          >
            <v-list-item-title v-html="$t('production_form_rows_310_csv')"></v-list-item-title>
          </v-list-item>
          <v-list-item
            id="production_form_rows_310_txt"
            key="production_form_rows_310_txt"
            v-if="
              $privileges.has({
                path: '/productionForms',
                permission: 'allDomains'
              })
            "
            @click="get310(-1, 'txt')"
          >
            <v-list-item-title v-html="$t('production_form_rows_310_txt')"></v-list-item-title>
          </v-list-item>
          <v-list-item
            id="production_form_rows_quantity_no_request_xlsx"
            key="production_form_rows_quantity_no_request_xlsx"
            v-if="
              $privileges.has({
                path: '/productionFormRows',
                permission: 'allDomains'
              })
            "
            @click="quantityWithNoRequestXlsx"
          >
            <v-list-item-title v-html="$t('production_form_rows_quantity_no_request_xlsx')"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
    <v-data-table-server
      :headers="computedHeaders"
      :items="items"
      :fields="fields"
      :loading="loading"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      :items-length="totalElements"
      :multi-sort="true"
      density="compact"
    >
      <template v-slot:[`item.validUntil`]="{ item }">
        <span v-if="item.validUntil">{{ format(item.validUntil, 'MM.yyyy') }}</span>
      </template>
      <!--      todo fix search period-->
      <template v-slot:[`item.edit`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionForms', permission: 'read' })">
          <router-link
            :to="{
              name: 'productionForms_read',
              query: { term: productionFormsTerm(item), size: 50 }
            }"
            class="tableaction link"
          >
            <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
          </router-link>
        </template>
        <template v-else>
          <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
        </template>
      </template>

      <template v-slot:[`item.agisId`]="{ item }">
        <template v-if="$privileges.has({ path: '/farms', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.farmId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.agisId }}
          </router-link>
        </template>
        <template v-else>
          {{ item.agisId }}
        </template>
      </template>
      <template v-slot:[`item.farmerName1`]="{ item }">
        <template v-if="$privileges.has({ path: '/legalEntities', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_legal_entities_edit',
              params: { id: item.farmerId, tab: 'overview' }
            }"
          >
            {{ item.farmerName1 ? item.farmerName1 : '-' }}
          </router-link>
        </template>
        <template v-else>
          {{ item.farmerName1 ? item.farmerName1 : '-' }}
        </template>
      </template>

      <template v-slot:[`item.dairyIdent`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.dairyId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.dairyIdent ? item.dairyIdent : '-' }}
          </router-link>
        </template>
        <template v-else>
          {{ item.dairyIdent ? item.dairyIdent : '-' }}
        </template>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        <template
          v-if="
            $privileges.has({
              path: '/subsidyInvoices/production',
              permission: 'read'
            })
          "
        >
          <router-link
            :to="{
              name: 'subsidy_invoices_production',
              query: { term: marketedMilkTerm(item), size: 50 }
            }"
            class="tableaction link"
          >
            {{ $formatNumber(item['quantity'], 1) }}
          </router-link>
        </template>
        <template v-else>
          {{ $formatNumber(item['quantity'], 1) }}
        </template>
      </template>

      <template v-slot:[`item.quantityCalculated`]="{ item }">
        {{ $formatNumber(item['quantityCalculated'], 1) }}
      </template>
    </v-data-table-server>

    <v-row justify="end">
      <v-col sm="6" md="4">
        <v-table density="compact" class="custom-simple-table">
          <tbody>
            <tr>
              <td><span v-html="$t('productionformrows_quantityTotal')" /></td>
              <td align="right">{{ $formatNumber(quantityTotal, 1) }}</td>
            </tr>
            <tr>
              <td><span v-html="$t('productionformrows_row_count')" /></td>
              <td align="right">{{ $formatNumber(totalElements, 0) }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'

import { fileCreator, showError } from '@/services/axios'
import { Term } from '@/services/term'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchFarmManaged from '@/components/searchCards/searchFarmManaged.vue'
import { useSearchFarmManagedStore } from '@/store/SearchFarmManagedStore'
import searchDairy from '@/components/searchCards/searchDairy'
import { useSearchDairyStore } from '@/store/SearchDairyStore'

import searchDairySupplied from '@/components/searchCards/searchDairySupplied'
import { useSearchDairySuppliedStore } from '@/store/SearchDairySuppliedStore'

import searchProduction from '@/components/searchCards/searchProduction.vue'
import { useSearchProductionStore } from '@/store/SearchProductionStore'
import { useProductionFormStatusStore } from '@/store/enums/ProductionFormStatusStore'

import searchProductionLabel from '@/components/searchCards/searchProductionLabel.vue'
import _ from 'lodash'
//http://localhost:8199/api/masterdata/search?term=applicationUser_applicationRoleId%3A1&domainId=1&roleId=1
import { DTSHeader } from '@/services/BackendService'
import { format, startOfMonth, endOfMonth, addYears } from 'date-fns'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'form_rows_read',
  props: {
    isFarmer: { type: Boolean, default: false, required: false }
  },
  components: {
    searchContact,
    searchLegalEntity,
    searchFarm,
    searchFarmManaged,
    searchDairy,
    searchDairySupplied,
    searchProduction,
    searchProductionLabel
  },
  data() {
    return {
      format: format,
      items: [],
      totalElements: 0,
      quantityTotal: 0,
      rowCountTotal: 0,
      loading: false,
      downloading: false,
      sumCheckbox: false,
      fields: <DTSHeader[]>[
        {
          title: '',
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        {
          title: this.$t('productionformrows_agisid'),
          key: 'agisId',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('productionformrows_farm_type'),
          key: 'farmType' + this.$getUpLangKey(),
          sortable: true
        },
        {
          title: this.$t('productionformrows_farmerName1'),
          key: 'farmerName1',
          sortable: true
        },
        {
          title: this.$t('productionformrows_farmerName2'),
          key: 'farmerName2',
          sortable: true
        },
        {
          title: this.$t('productionformrows_farmzip'),
          key: 'farmerZip',
          sortable: false,
          align: 'left'
        },
        {
          title: this.$t('productionformrows_farmlocality'),
          key: 'farmerLocality',
          sortable: false
        },
        {
          title: this.$t('productionformrows_dairyName'),
          key: 'dairyName',
          sortable: true
        },
        {
          title: this.$t('productionformrows_dairyident'),
          key: 'dairyIdent',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('productionformrows_reportingperiod_month'),
          key: 'validUntil',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('productionformrows_rowCountByFarm'),
          key: 'rowCountByFarm',
          sortable: false,
          align: 'end'
        },
        {
          title: this.$t('productionformrows_animaltype'),
          key: 'animalType' + this.$getUpLangKey(),
          sortable: true
        },
        {
          title: this.$t('productionformrows_status'),
          key: 'statusName',
          sortable: false
        },
        {
          title: this.$t('productionformrows_version_no'),
          key: 'versionNo',
          align: 'left',
          sortable: true
        },
        {
          title: this.$t('productionformrows_quantity_kg'),
          key: 'quantity',
          sortable: true,
          align: 'end'
        },
        {
          title: this.$t('productionformrows_quantityCalculated_column'),
          key: 'quantityCalculated',
          sortable: true,
          align: 'end'
        }
      ]
    }
  },
  computed: {
    params() {
      return {
        validFrom: useSearchProductionStore().validFrom,
        validUntil: useSearchProductionStore().validUntil,
        createdFrom: useSearchProductionStore().createdFrom,
        createdUntil: useSearchProductionStore().createdUntil,
        isCorrection: useSearchProductionStore().isCorrection,
        animalTypeId: useSearchProductionStore().animalTypeId,
        quantityFrom: useSearchProductionStore().quantityFrom,
        quantityTo: useSearchProductionStore().quantityTo,
        milkQualityTesting: useSearchProductionStore().milkQualityTesting,
        accumulate: useSearchProductionStore().accumulate,
        latestRowsOnly: useSearchProductionStore().latestRowsOnly,
        labelTypeId: useSearchProductionStore().labelTypeId,
        complied: useSearchProductionStore().complied
      }
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions({ sort: ['validUntil,desc', 'farmerName1,asc', 'versionNo,desc'] })
      }
    },
    term(): any {
      return [
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchFarmManagedStore(), useSearchFarmManagedStore().items),
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchDairySuppliedStore(), useSearchDairySuppliedStore().items),
        ...(!this.isFarmer ? Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items) : []),
        ...(!this.isFarmer ? Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items) : [])
      ]
    },
    computedHeaders(): any {
      if (this.sumCheckbox) {
        return this.fields.filter((s) => s.value !== 'validFrom')
      } else {
        return this.fields.filter((s) => s.value !== 'rowCountByFarm')
      }
    },
    productionFormStatus() {
      return useProductionFormStatusStore().productionFormStatus
    }
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async download() {
      this.downloading = true
      try {
        let url = '/productionFormRows/find'
        if (this.isFarmer) url = '/productionFormRows/findFarmer'
        const response = await this.axios.get(apiURL + url, {
          params: {
            term: Term.stringify(this.term),
            ...this.params
          },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'productionFormRows.xlsx')
      } catch (e) {
        console.error(e)
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    async quantityWithNoRequestXlsx() {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + '/productionFormRows/findNoCreditor', {
          params: {
            validFrom: useSearchProductionStore().validFrom,
            validUntil: useSearchProductionStore().validUntil
          },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(response, 'productionFormRowsNoCreditor.xlsx')
      } catch (e) {
        console.error(e)
        let responseObj = e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    async get310(year, type) {
      this.downloading = true
      try {
        let year = format(addYears(new Date(), -1), 'yyyy')
        const response = await this.axios.get(apiURL + '/productionFormRows/find310', {
          params: {
            productionFormYear: year
          },
          headers: {
            Accept: 'text/' + (type === 'txt' ? 'plain' : type)
          },
          responseType: 'blob'
        })
        fileCreator(response, '310_' + year + '.' + type)
      } catch (e) {
        console.error(e)
        let responseObj = e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    // add alert if it's not working
    async load() {
      this.loading = true
      try {
        this.items = []
        let url = '/productionFormRows/find'
        if (this.isFarmer) url = '/productionFormRows/findFarmer'
        const response = await this.axios.get(apiURL + url, {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        // get total row count for quantityTotal
        if (this.items.length > 0) {
          this.totalElements = this.items[0].rowCountTotal
        }
        this.items.forEach((row) => {
          //accumulated rows won't have a status
          if (row.status) {
            row.statusName = this.productionFormStatus.find((status) => status.name === row.status)[this.$getLangKey()]
          }
        })
      } catch (e) {
        console.error(e)
        showError(e)
      }
      try {
        let url = '/productionFormRows/getTotalQuantity'
        if (this.isFarmer) url = '/productionFormRows/getTotalQuantityFarmer'
        const quantityTotal = await this.axios.get(apiURL + url, {
          params: {
            term: Term.stringify(this.term),
            ...this.params
          }
        })
        if (await quantityTotal) {
          this.quantityTotal = await quantityTotal.data.quantityTotal
        }
      } catch (error) {
        console.error(error)
        showError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      if (this.$refs['search-contact']) this.$refs['search-contact'].reset()
      if (this.$refs['search-legal-entity']) this.$refs['search-legal-entity'].reset()
      if (this.$refs['search-farm']) this.$refs['search-farm'].reset()
      if (this.$refs['search-farm-managed']) this.$refs['search-farm-managed'].reset()
      if (this.$refs['search-dairy']) this.$refs['search-dairy'].reset()
      if (this.$refs['search-dairy-supplied']) this.$refs['search-dairy-supplied'].reset()
      this.$refs['search-production'].reset()
    },
    onClickCheckboxSum(value) {
      this.sumCheckbox = value
    },
    productionFormsTerm(item) {
      // Navigation zu  mit Periode, MBH-ID und AGIS-ID Betrieb
      const from = format(item.validFrom, 'yyyy-MM-dd')
      return Term.stringify([
        { key: 'Dairy_ident', value: item.dairyIdent, operation: ':' },
        { key: 'Farm_agisId', value: item.agisId, operation: ':' },
        { key: 'period', value: from, operation: '~' }
      ])
    },
    marketedMilkTerm(item) {
      // MPD1-Meldungen von/bis und der AGIS-ID Betrieb
      const from = format(startOfMonth(item.validFrom), 'yyyy-MM-01')
      const until = format(endOfMonth(item.validUntil), 'yyyy-MM-dd')
      return Term.stringify([
        { key: 'Farm_agisId', value: item.agisId, operation: ':' },
        { key: 'validFrom', value: from, operation: '~' },
        { key: 'validUntil', value: until, operation: '~' }
      ])
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue, oldValue) {
        this.load()
      },
      deep: true
    }
  },
  beforeMount() {
    useProductionFormStatusStore().fetchProductionFormStatus()
  },
  mounted() {
    if (this.isFarmer) {
      this.fields = this.fields.filter((fld) => fld.value !== 'versionNo')
    }
    if (this.$route.query.size) this.load()
  }
})
</script>
